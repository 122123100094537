import React, { useContext, useEffect } from 'react'
import { RequestContext } from '../context/Store'
import { API_ERROR, LOAD_REQUESTS, SET_CURR_REQUEST } from '../context/Actions';
//import { FcAddRow, FcRight } from 'react-icons/fc';
import { AddCircle, EditOutlined, DeleteOutlineOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import './RequestList.css';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { getRequests,deleteReq,createReq } from '../api';
export default function RequestsList() {
    const apiURL = process.env.REACT_APP_GA4_APIURL;
    const apiKey = process.env.REACT_APP_GA4_APIKEY;
    const apiKeyValue = process.env.REACT_APP_GA4_APIKEY_VALUE;
    const apiScope = process.env.REACT_APP_GA4_API_SCOPE;
    const [state, dispatch] = useContext(RequestContext);
    const { instance, inProgress, accounts } = useMsal();

    const deleteRequest = (id) => {
        deleteReq(inProgress,instance,accounts,id)
        .then((result) => {
            getRequests(inProgress,instance,accounts)
            .then(                         (result) => {
                                     dispatch({ type: LOAD_REQUESTS, payload: result })
                                 },
                                 (error) => {
                                     console.log(error);
                                     dispatch({ type: API_ERROR, payload: error })
                                 }
                )
        }
        )        

    }
    const createNewRequest = () => {
        createReq(inProgress,instance,accounts)
            .then(
                (result) => {
                    dispatch({ type: SET_CURR_REQUEST, payload: result })
                },
                (error) => {
                    console.log(error);
                    dispatch({ type: API_ERROR, payload: error })
                }
            )        
    }

    useEffect(() => {
        if (inProgress === InteractionStatus.None) {
            getRequests(inProgress,instance,accounts)
            .then(                         (result) => {
                                     dispatch({ type: LOAD_REQUESTS, payload: result })
                                 },
                                 (error) => {
                                     console.log(error);
                                     dispatch({ type: API_ERROR, payload: error })
                                 }
                )
        }
    }, [dispatch, apiURL, apiKey, apiKeyValue,accounts,inProgress,instance,apiScope]);


    return <table className="table">
        <thead>
            <tr>

                <th className="id">ID</th>
                <th className="desc">Description</th>
                <th className="cmd"><Tooltip title="Create New Request"><AddCircle color='primary' className="icon"
                    onClick={createNewRequest} sx={{ cursor: 'pointer' }} /></Tooltip></th>
                <th />
            </tr>
        </thead>
        <tbody>
            {
                state.data?.map((d) => {
                    return <tr key={d.requestID}>
                        <td>{d.requestID}</td>
                        <td>{d.requestDesc}</td>
                        <td><Tooltip title="Edit Request"><EditOutlined color='primary' sx={{ cursor: 'pointer' }}
                            className="icon" onClick={() => { dispatch({ type: SET_CURR_REQUEST, payload: { ...d } }) }} /></Tooltip></td>
                        <td><Tooltip title="Delete Request"><DeleteOutlineOutlined color='error' sx={{ mx: 2, cursor: 'pointer' }}
                            className="icon" onClick={() => { deleteRequest(d.requestID) }} /></Tooltip></td>
                    </tr>
                })
            }
        </tbody>
    </table>
}


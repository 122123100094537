import React from 'react'

import RequestsList from './RequestsList';
import Request from './Request';
import './Requests.css';
export default function Requests() {
  return (



  <>
    <div className='maincontent'>
      <div></div>
      <div className='left'>
      <div className="head"> Available Requests </div>

      <RequestsList></RequestsList>
      </div>
      <div className='right'>
      <div className="head"> Request </div>
      <Request></Request>
      </div>
      <div></div>
    </div>
  </>
  )
}


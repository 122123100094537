import React,{createContext,useReducer,useContext} from 'react'
import requestReducer from './Reducer';

const intialState = {
  data: [],
  isLoading: true,
  isError:false,
  error:{}
}
const RequestStore = ({children}) => {
  const [state,dispatch] = useReducer(requestReducer,intialState);
  return (
    <RequestContext.Provider value={[state,dispatch]}>{children}</RequestContext.Provider>
  )
}


export const RequestContext = createContext(intialState);
export default RequestStore;

export function useRequestContext() {
  return useContext(RequestContext);
}
export const fetchProfile = (accessToken) => {
    const endpoint = "https://graph.microsoft.com/v1.0/me"
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(endpoint, options)
        .then(result => result.json())
        .catch(error => console.log(error));
}

export const fetchGroup = (accessToken,id) =>{
    const endpoint = `https://graph.microsoft.com/v1.0/users/${id}/memberOf/microsoft.graph.group?$count=true&$select=displayName`;
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(endpoint, options)
        .then(result => result.json())
        .catch(error => console.log(error));

}
import React from 'react'
import { useState, useEffect } from 'react';
import { useMsalAuthentication } from '@azure/msal-react';
import { BrowserAuthError, InteractionType } from '@azure/msal-browser';
import { fetchProfile, fetchGroup } from '../graph';
import ProfileData from '../components/ProfileData';
export default function Profile() {
  const apiScope = process.env.REACT_APP_GA4_API_SCOPE;
  const [graphData, setGraphData] = useState(null);
  const { result, error, login } = useMsalAuthentication(InteractionType.Popup, {
    scopes: ['user.read','GroupMember.Read.All', apiScope]
  });

  

  useEffect(() => {
    if (!!graphData) {
      return
    }

    if (!!error) {
      if (error instanceof BrowserAuthError) {
        login(InteractionType.Redirect, {
          scopes: ['user.read','GroupMember.Read.All', apiScope]
        })
      }
      console.log(error);
    }

    if (result) {
      fetchProfile(result.accessToken)
        .then(data => {
          setGraphData(data);
          console.log(data);
          fetchGroup(result.accessToken,data.id)
          .then(data=>{
            console.log(data);
          }).catch(error=>{
            console.log(error)
          })
        }
        )
        .catch(error => console.log(error));
    }
  }, [error, result, graphData, login,apiScope]);


  if (error) {
    return <div>Error: {error.message}</div>;
  }


  return (
    <>
      {graphData?<ProfileData graphData={graphData} />:null}
    </>
  )
}

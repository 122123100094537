
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Profile from './pages/Profile';
import PageLayout from './components/PageLayout';
import Grid from '@mui/material/Grid';
import GAREQ from './apps/GAREQ';
import { MsalProvider } from '@azure/msal-react';

function App({ msalInstance }) {

  return (
    <div className="App">
      <MsalProvider instance={msalInstance}>
        <PageLayout>
          <Grid container justifyContent="center">
            <Pages />
          </Grid>
        </PageLayout>
      </MsalProvider>
    </div>
  );
}

const Pages = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/profile" element={<Profile />}></Route>
      <Route path="/gareq" element={<GAREQ />}></Route>
    </Routes>
  )
}



export default App;

import React from 'react'
import NavBar from './NavBar'
import './PageLayout.css'
export default function PageLayout(props) {
  return (
    <> 
      <div className="box">
        <div className="row header">
          <NavBar />
        </div>
        <div className="row content">
          <div className='mainContentArea'>
              {props.children}
              </div>
        </div>
        <div className="row footer">
          <p><b>footer</b> (fixed height)</p>
        </div>
      </div>


    </>
  )
}

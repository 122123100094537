import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './styles/theme';
import { PublicClientApplication } from '@azure/msal-browser';
import { EventType } from '@azure/msal-browser';
const root = ReactDOM.createRoot(document.getElementById('root'));

const pca = new PublicClientApplication({
  auth: {
      clientId: '47a617de-0578-41d2-9cf5-cd5b3fe27385',
      authority:'https://login.microsoftonline.com/c89bfa88-67fd-4ff0-a3b0-3b42e26ece89',
      redirectUri: '/',
      postLogoutRedirectUri: '/',

  },
  cache: {
    cacheLocation: "localStorage",
    //temporaryCacheLocation: "sessionStorage",
    //storeAuthStateInCookie: false,
    secureCookies: false
  },
  system: {
      // loggerOptions: {
      //     loggerCallback: (level, message, containsPII) => {
      //          console.log(message)
      //      },
      //      logLevel: "none"
      //  }
  }
});

pca.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
      pca.setActiveAccount(event.payload.account);
  }
});


root.render(
 
    <BrowserRouter>
    <ThemeProvider theme={theme}>
      <App msalInstance={pca}/>
      </ThemeProvider>
    </BrowserRouter>
 
);



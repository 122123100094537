import React from 'react'
import { useIsAuthenticated } from '@azure/msal-react';
export default function Home() {
  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      {!isAuthenticated ? <p>You must Sign In</p> : <p>Welcome to KHOV Web Apps!</p>}
    </>
  )
}

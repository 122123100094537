import React from 'react'
import RequestStore from './context/Store'
import Requests from './components/Requests'
import { useIsAuthenticated } from '@azure/msal-react';
import { Typography } from '@mui/material';
export default function GAREQ() {
    let isAuthenticated = useIsAuthenticated();
    //if(isAuthenticated)   console.log(process.env);
  return (
    <RequestStore>
        {!isAuthenticated? <Typography variant='h6'>Unauthorized!</Typography> :<Requests />}
    </RequestStore>
  )
}

import { API_ERROR, LOAD_REQUESTS, SET_CURR_REQUEST,UPDATE_CURR_REQUEST } from "./Actions";

const requestReducer = (state,action)=>{
    console.log('Reducer!!!');
    switch(action.type){

        case LOAD_REQUESTS:
            return {
                ...state,
                data: action.payload,
                isLoading:false,
                isError:false
            }
        case API_ERROR:
            return{
                ...state,
                isLoading:false,
                isError:true,
                error: action.payload,
                data:[]
            }
         case SET_CURR_REQUEST:
            {let req = state.data.find((o=>o.requestID===action.payload.requestID))
            if(!req)req=action.payload;
            return{
                ...state,
                currRequest: {...req}
            }}
            case UPDATE_CURR_REQUEST:
              return {
                ...state,
                currRequest: {...action.payload}
            }
        default:
            return {...state};
    }

}


export default requestReducer;
import AppBar from "@mui/material/AppBar";
import { useState, useEffect } from "react";
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from "@mui/material/Toolbar";
import Divider from '@mui/material/Divider';
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuIcon from '@mui/icons-material/Menu';
import React from 'react'
import { Link as RouterLink } from "react-router-dom";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

export default function NavBar() {
  const apiScope = process.env.REACT_APP_GA4_API_SCOPE;
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [anchorEl, setAnchorEl] = useState(null);
  const [name, setName] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSignIn = () => {
    instance.loginRedirect({
      scopes: ['user.read','GroupMember.Read.All', apiScope],
    });
  }

  useEffect(()=>{
    const currentAccount = instance.getActiveAccount();

    if (currentAccount) {
        setName(currentAccount.name);
    }
  },[instance,isAuthenticated]);
  return (
    <div sx={{ flexGrow: 1 }}>
      <AppBar position='static'>
        <Toolbar>
          <MenuIcon onClick={handleClick} color="white" />
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose} disableRipple>

              <Link component={RouterLink} to="/gareq" >Google Analytics Requets</Link>
            </MenuItem>

            <Divider sx={{ my: 0.5 }} />
            <MenuItem onClick={handleClose} disableRipple>
              <Link component={RouterLink} to="/profile" >Profile</Link>
            </MenuItem>
          </StyledMenu>
          <Typography sx={{ flexGrow: 1 }} color="white">
            KHOV Web Apps
          </Typography>
          {!isAuthenticated ? <Button color="infowhite" onClick={handleSignIn}>Sign In</Button> : <Typography color='white' variant='h8'>Welcome {name}</Typography>}
        </Toolbar>
      </AppBar>
    </div>
  )
}



const StyledMenu = styled((props) => (
  <Menu
    elevation={3}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 181)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));
import { InteractionStatus } from "@azure/msal-browser";

async function getToken(inProgress,instance,accounts){

    const apiScope = process.env.REACT_APP_GA4_API_SCOPE;

    if (inProgress === InteractionStatus.None) {
        const accessTokenRequest = {
            scopes: ["user.read",'GroupMember.Read.All', apiScope],
            account: accounts[0],
        };
        
        const res = await instance
            .acquireTokenSilent(accessTokenRequest)
            return res.idToken;    
    }    
}

export async function getRequests(inProgress,instance,accounts){
    const apiURL = process.env.REACT_APP_GA4_APIURL;
    const apiKeyValue = process.env.REACT_APP_GA4_APIKEY_VALUE;
    const token = await getToken(inProgress,instance,accounts);
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 
        'Ocp-Apim-Subscription-Key': apiKeyValue,
        'Authorization': `Bearer ${token}` },
    };    
    const res = await fetch(`${apiURL}Requests`, requestOptions);
    return res.json();
}

export async function deleteReq(inProgress,instance,accounts,id){
    const apiURL = process.env.REACT_APP_GA4_APIURL;
    const apiKeyValue = process.env.REACT_APP_GA4_APIKEY_VALUE;
    const token = await getToken(inProgress,instance,accounts);
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 
        'Ocp-Apim-Subscription-Key': apiKeyValue ,
        'Authorization': `Bearer ${token}` },
        body: JSON.stringify({})
    };
    const resDel = await fetch(`${apiURL}Request/${id}`, requestOptions);
    return resDel.json();
}

export async function createReq(inProgress,instance,accounts){
    const apiURL = process.env.REACT_APP_GA4_APIURL;
    const apiKeyValue = process.env.REACT_APP_GA4_APIKEY_VALUE;
    const token = await getToken(inProgress,instance,accounts);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': apiKeyValue,'Authorization': `Bearer ${token}` },
        body: JSON.stringify({})
    };    
    const resCreate = await fetch(`${apiURL}CreateRequest`, requestOptions);
    return resCreate.json();
}


export async function saveRequest(inProgress,instance,accounts,req){
    const apiURL = process.env.REACT_APP_GA4_APIURL;
    const apiKeyValue = process.env.REACT_APP_GA4_APIKEY_VALUE;
    const token = await getToken(inProgress,instance,accounts);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' ,'Ocp-Apim-Subscription-Key': apiKeyValue,'Authorization': `Bearer ${token}`},
        body: JSON.stringify(req)
    };
    const res = await fetch(`${apiURL}Request`,requestOptions);
    return res.json();
}

export async function testRequest(inProgress,instance,accounts,req){
    const apiURL = process.env.REACT_APP_GA4_APIURL;
    const apiKeyValue = process.env.REACT_APP_GA4_APIKEY_VALUE;
    const token = await getToken(inProgress,instance,accounts);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json','Ocp-Apim-Subscription-Key': apiKeyValue,'Authorization': `Bearer ${token}`},
        body: JSON.stringify(req)
    }
    const res = await fetch(`${apiURL}TestRequest`,requestOptions);
    return res.json();
}
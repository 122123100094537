import React, { useEffect, useState } from "react";
import { useRequestContext } from "../context/Store";
import "./Request.css";
import { UPDATE_CURR_REQUEST,LOAD_REQUESTS,API_ERROR } from "../context/Actions";
import { Button,Tooltip } from "@mui/material";
import _ from "lodash";
import clsx from "clsx";
import { DescriptionOutlined } from "@mui/icons-material";
import { useMsal } from "@azure/msal-react";
import { saveRequest as saveReq,testRequest as testReq,getRequests } from "../api";
export default function Request() {  
  const [state, dispatch] = useRequestContext();
  const [testResult,setTestResult] = useState(null);
  const [showSQL,setShowSQL]=useState(false);
  const [sqlStr,setSqlStr] = useState("");
  const { instance, inProgress, accounts } = useMsal();

  const testRequest = ()=>{
    let obj = {...state.currRequest};
    testReq(inProgress,instance,accounts,obj)
    .then(
      (result) => {
        console.log(result);
        setTestResult(result);
    },
    (error) => {
        setTestResult({data: JSON.stringify(error)})
    })
   };
   const saveRequest = ()=>{
    let obj = {...state.currRequest};
    saveReq(inProgress,instance,accounts,obj)
    .then(()=>{
      getRequests(inProgress,instance,accounts)
      .then(
        (result) => {
            dispatch({ type: LOAD_REQUESTS, payload: result })
        },
        (error) => {
            dispatch({ type: API_ERROR, payload: error })
        }
    ) 
    })
   };

   const handleShowSQL = () =>{
    //Generate create table sql
    let sql = `CREATE TABLE [dbo].${state?.currRequest?.destTable} ([filename] [nchar](255) ,[seq] [bigint] ,[date] [nchar](8),`;
    state?.currRequest?.dimensions?.split(',').map((d)=>{
      if(!(d==="date")) sql+=`[${d}] [nchar](255) NULL,`;
      return null;
    });
    state?.currRequest?.metrics?.split(',').map((d)=>{
      sql+=`[${d}] [float] NULL,`;
      //sql=sql.replace(/.$/,")");
      return null;
    });
    sql+=` CONSTRAINT [PK_${state?.currRequest?.destTable}] PRIMARY KEY CLUSTERED 
    (
      [filename] ASC,
      [seq] ASC
    ) ON [PRIMARY])`;
    setSqlStr(sql);
    setShowSQL(true);
   };

   useEffect(()=>{
    setTestResult(null);
    setShowSQL(false);},[state]);

   const handleFormChange = (e)=>{
    setTestResult(null);
    console.log('handleFormChange');
    console.log(e);
    let form = document.querySelector('#request');
    let data = new FormData(form);
    let formData = {};    
     for (let [key, value] of data) {
         formData[key] = value;
     }
    //let obj = _.assign({...state.currRequest},{...formData});
    dispatch({type: UPDATE_CURR_REQUEST,payload: _.merge({...state.currRequest},{...formData})});
   };
   if(!state.currRequest?.requestID){
    return (<></>)
   }
  return (
    <>
    <form id='request'>
      <div className="formfield">
        <label className="reqlabel" htmlFor="requestID">ID:</label>
        <input
          id="id"
          name="requestID"
          type="text"
          disabled
          value={state.currRequest?.requestID || ""}
        />
      </div>
      <div className="formfield">
        <label className="reqlabel" htmlFor="description">Description:</label>
        <input
          id="description"
          name="requestDesc"
          type="text"
          onChange={(e)=>handleFormChange(e)}
          value={state.currRequest?.requestDesc || ""}
          // defaultValue={state.currRequest?.requestDesc || ""}
        />
      </div>
      <div className="formfield">
        <label className="reqlabel" htmlFor="dimensions">Dimensions:</label>
        <textarea
          id="dimensions"
          name="dimensions"
          rows={4}
          cols={40}
          onChange={(e)=>handleFormChange(e)}
          value={state.currRequest?.dimensions || ""}
          // defaultValue={state.currRequest?.dimensions?.join(",") || ""}
        />
      </div>
      <div className="formfield">
        <label className="reqlabel" htmlFor="metrics">Metrics:</label>
        <textarea
          id="metrics"
          name="metrics"
          rows={4}
          cols={40}
          onChange={(e)=>handleFormChange(e)}
          value={state.currRequest?.metrics || ""}
          // defaultValue={state.currRequest?.metrics?.join(",") || ""}
        />
      </div>
      <div className="formfield">
        <label className="reqlabel" htmlFor="destTable">Target Table:</label>
        <input
          id="destTable"
          name="destTable"
          type="text"
          onChange={(e)=>handleFormChange(e)}
          value={state.currRequest?.destTable || ""}
          // defaultValue={state.currRequest?.requestDesc || ""}
        />
        {testResult?.success?<Tooltip title="Show Create Table SQL"><DescriptionOutlined color='primary' sx={{cursor:'pointer'}}
                            className="icon" onClick={() => { handleShowSQL() }}/></Tooltip>:null}
      </div>      
      </form>
      <div className="formfield">
        <Button  color='primary' sx={{mx: 0,color:'white'}} variant="contained" onClick={()=>{testRequest()}}>Test Request</Button>
        {testResult?.success?
        <Button  color='primary' sx={{mx: 0.5,color:'white'}} variant="contained" onClick={()=>{saveRequest()}}>Save Request</Button>
      :null}
      </div>
      <div>
        <textarea disabled={true} rows={12} className={`testResult${clsx({Error:testResult?.error},{Success:testResult?.success})}`}
           value={(testResult?.message || "") + "\n" + (testResult?.data || "")}>
          
        </textarea>
      </div>

      <div>
        <a href='https://ga-dev-tools.google/ga4/dimensions-metrics-explorer/' target='_blank' rel='noreferrer'>Dimensions and Metric Explorer</a>
      </div>
      {showSQL?<div>
        <textarea disabled={true} rows={12} className={`testResult`}
           value={sqlStr}>
        </textarea>
      </div>:null}
    </>
  );
}
